$( document ).ready(function() {

  // Barba - Init
  Barba.Pjax.Dom.wrapperId = 'l-page'
  Barba.Pjax.Dom.containerClass = 'l-page-wrapper'
  Barba.Pjax.start();

  // Barba - Transition
  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
      .all([this.newContainerLoading, this.fadeOut()])
      .then(this.fadeIn.bind(this));
    },

    fadeOut: function() {
      return $(this.oldContainer).animate({ opacity: 0 }).promise();
    },

    fadeIn: function() {

      var _this = this;
      var $el = $(this.newContainer);

      $(this.oldContainer).hide();

      $el.css({
        visibility : 'visible',
        opacity : 0
      });

      $el.animate({ opacity: 1 }, 400, function() {
        _this.done();
      });
    }
  });

  Barba.Pjax.getTransition = function() {
    return FadeTransition;
  };

  // Barba - update metatag
  Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, container, newPageRawHTML) {
    // html head parser borrowed from jquery pjax
    var $newPageHead = $( '<head />' ).html(
      $.parseHTML(
        newPageRawHTML.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0]
        , document
        , true
        )
      );
    var headTags = [
    "meta[name='keywords']"
    , "meta[name='description']"
    , "meta[property^='og']"
    , "meta[name^='twitter']"
    , "meta[itemprop]"
    , "link[itemprop]"
    , "link[rel='prev']"
    , "link[rel='next']"
    , "link[rel='canonical']"
    ].join(',');
    $( 'head' ).find( headTags ).remove(); // Remove current head tags
    $newPageHead.find( headTags ).appendTo( 'head' ); // Append new tags to the head
  });
});